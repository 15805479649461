<template>
  <el-row class="footer" type="flex">
    <el-col :xs="2" :sm="2" :md="2" :lg="1" :xl="1"></el-col>
    <el-col :xs="4" :sm="4" :md="4" :lg="3" :xl="2" class="left">
      <el-row class="item1">
        <el-col :span="24" class="pcdblogo">
          <img src="../assets/images/LOGO.png" alt="">
        </el-col>
        <el-col :span="24" class="yddblogo">
          <img src="../assets/images/logo.4b76fdb6.png" alt="">
          <span>
            逸航星云
          </span>
        </el-col>
        <el-col :span="24" class="lianxi">
          <span class="iconfont icon-qq"></span>
          <span class="iconfont icon-weixin"></span>
          <span class="iconfont icon-31dianhua"></span>
          <span class="phone">15198263493</span>
        </el-col>
        <el-col class="btn" :span="24">
          <el-button>逸航星云</el-button>
        </el-col>
      </el-row>
    </el-col>
    <el-col :xs="2" :sm="2" :md="2" :lg="1" :xl="1" class="fenge">
    </el-col>
    <el-col :xs="2" :sm="2" :md="2" :lg="1" :xl="1" style="height: 80%"></el-col>
    <el-col :xs="10" :sm="14" :md="14" :lg="18" :xl="19" class="right">
      <el-row class="item2">
        <el-col class="list" :span="24">
          <router-link to="/home">首页</router-link>
          <router-link to="">|</router-link>
          <router-link to="/case">案列</router-link>
          <router-link to="">|</router-link>
          <router-link to="/new">新闻</router-link>
          <router-link to="">|</router-link>
          <router-link to="/about">关于-简介</router-link>
          <!-- <router-link to="">|</router-link> -->
          <!-- <router-link to="/Introduce">关于-荣誉</router-link> -->
        </el-col>
        <el-col class="list1" :span="24">
          <el-row>
            <el-col class="text1">
              <span>APP设计开发</span>
              <span>官网设计开发</span>
              <span>管理平台设计开发</span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" class="text2">
              <span>内容美化</span>
              <span>标准化</span>
              <span>便捷应用</span>
            </el-col>
          </el-row>
        </el-col>
        <el-col class="list2" :span="24">
          <span>办公地点：中国四川省成都市龙泉驿区成龙大道二段1666号经开科技产业孵化园B2-1-302</span>
        </el-col>
        <el-col class="list3" :span="24">
          <!-- <span>© 2019-2022
            <a href="https://beian.miit.gov.cn/">蜀ICP备2022010197号-1</a>
          </span> -->
          <span>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51011202000522"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
              <img src="../../public/images/batb.png" style="float:left;" />
              <span style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">川公网安备
                51011202000522号</span>
            </a>

            <a target="_blank" href="https://beian.miit.gov.cn/"
              style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
              <span
                style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">蜀ICP备2023000834号-1
              </span>
            </a>
          </span>
          <span>
            <!-- <a href=""></a> -->
          </span>
          <!-- <div style="width:300px;margin:0 auto; padding:20px 0;">

          </div> -->
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: 'FooterIndex',
  data() {
    return {
      a: 0
    }
  },


  mounted() {
    const footerW = document.querySelector('.footer').clientHeight
    this.$store.state.dibugao = footerW
  }
}
</script>
<style lang="less" scoped>
.footer {
  background-color: rgb(51, 51, 51);
  height: 260px;
  align-items: center;


  .left {
    .item1 {
      img {
        height: 20px;
        margin-bottom: 20px;
      }

      .yddblogo {
        display: none;
      }

      .el-col {
        padding: 4px;
      }

      .iconfont {
        color: white;
        margin-right: 20px;
        font-size: 20px;
      }

      .icon-31dianhua {
        font-size: 25px;
      }

      .phone {
        font-size: 20px;
        font-weight: 500;
        color: #1FD094;
      }

      .btn {
        margin-top: 21px;
        width: 100%;

        .el-button {
          width: 100%;
          //width: 175px;
          height: 40px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .right {
    .item2 {
      .list {
        height: 30px;

        a {
          color: #F8F8F8;
          line-height: 30px;
          // padding-right: 15px;
          padding: 0 7px;
        }
      }

      .list1 {
        height: 50px;
        //width: 600px;
        //display: flex;
        //flex-direction: column;
        margin-top: 5px;

        .text1 {
          span {
            padding-right: 6px;
            color: #8C8C8C;
            font-size: 13px;
          }
        }

        .text2 {
          margin-top: 10px;

          span {
            padding-right: 6px;
            color: #8C8C8C;
            font-size: 13px;
          }
        }
      }

      .list2 {
        //width: 560px;
        margin-top: 30px;

        span {
          color: #B3B3B3;
          font-size: 14px;
        }
      }

      .list3 {
        //width: 400px;
        margin-top: 20px;
        // display: flex;

        span {
          display: block;
          // color: #535353;
          font-size: 13px;
          // margin: auto;
        }
      }
    }
  }
}

@media screen and (max-width:940px) {
  .footer {
    background-color: rgb(51, 51, 51);
    height: auto;
    align-items: center;
    display: flex;
    flex-direction: column;

    .left {
      width: 100%;

      .item1 {
        width: 100%;
        display: flex;
        flex-direction: column;

        .pcdblogo {
          display: none;
        }

        .yddblogo {
          display: block;
          position: relative;

          img {
            margin: 15px;
            height: 24px;
          }

          span {
            font-size: 18px;
            font-weight: normal;
            color: #B3B3B3;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
            margin-left: 50px;
          }
        }

        .lianxi {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 14px;
            line-height: 14px;
          }

          .iconfont {
            text-align: center;
            width: 15px;
            height: 15px;
            border: 1px solid rgb(240, 240, 240);
            border-radius: 50%;
            padding: 2px;
            margin-right: 10px;
          }

        }

        .btn {
          display: none;
        }
      }
    }

    .fenge {
      width: 90%;
      height: 1px;
      background: #B3B3B3;
      margin: 10px 0 15px;
    }

    .right {
      width: 100%;

      .item2 {
        display: flex;
        flex-direction: column;

        .list {
          display: flex;
          justify-content: center;
          /* padding: 0 35px; */
          font-size: 14px;
          white-space: nowrap;

          a {
            color: #B3B3B3;
          }
        }

        .list1 {
          display: flex;
          flex-direction: column;

          .text1,
          .text2 {
            text-align: center;

            span {
              font-size: 12px;
            }
          }
        }

        .list2 {
          display: flex;
          padding: 0 70px;
          margin-bottom: 10px;
          justify-content: center;

          span {
            font-size: 12px;
            text-align: center;
          }
        }

        .list3 {
          text-align: center;
          margin: 15px 0;

          span {
            margin: auto;
            display: block;
            font-size: 12px;
          }
        }
      }

    }
  }
}
</style>
