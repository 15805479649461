<template>
  <div id="app">

    <div id="appbooy">
      <Header></Header>
      <router-view />
    </div>

    <!-- <Footer></Footer> -->
    <Footer id="footer" :class="{ 'footerBox': flag, 'footerPosition': !footerNum }"></Footer>
  </div>
</template>
<script>

import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import { getfind, fileUrls } from '@/api/index.js'

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      flag: true,
      fileUrls,
      intervalId: null,
      footerNum: false,
    }
  },
  created() {

    this.find()
  },
  mounted() {
    setTimeout(() => {
      this.flag = false
    }, 1000)
    this.intervalId = setInterval(() => {
      const booyHeight = document.querySelector('#appbooy').clientHeight
      const footerHeight = document.querySelector('#footer').clientHeight
      const winHeight = window.innerHeight
      // this.$store.state.footerNum = booyHeight > winHeight
      this.footerNum = footerHeight > winHeight - booyHeight
      // console.log(booyHeight, 'booyHeight', winHeight, 'winHeight', footerHeight, 'footerHeight');
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    async find() {
      this.findDate = {}
      const res = await getfind()
      // console.log(res, '---1-----');
      // console.log(res.code === 200, 'res.code=' + res.code)
      if (res.code === 200 && res.data) {
        // console.dir(res, '+++++++++++')
        this.findDate = {
          pic: this.fileUrls + res.data.pic,
          id: res.data.id,
          title: res.data.title,
          enable: res.data.enable
        }
        let favicon = document.querySelector('link[rel="icon"]')
        document.title = this.findDate.title;
        let newIcon = new Image()
        newIcon.src = this.findDate.pic
        newIcon.onload = function () {
          favicon.href = newIcon.src
        }
      }
      // console.log(this.findDate);
    },
  }
}

</script>
<style lang="less" scoped>
.footerPosition {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.footerBox {
  // position: fixed;
  // bottom: 0;
  display: none;
}
</style>
