<template>
  <el-row class="header" type="flex" align="middle" :style="topNavBg">
    <el-col :span="16" class="left">
      <!-- <img src="../assets/images/LOGO.png" alt=""> -->
      <img src="../assets/images/logo.4b76fdb6.png" alt="">
      <span>
        逸航科技
      </span>
    </el-col>
    <el-col :span="4" class="navbar">
      <el-row v-for="(item, index) in datas" :key="index">
        <el-menu router background-color="transparent" mode="horizontal" menu-trigger="click">
          <el-submenu index="1" :popper-append-to-body="false">
            <template slot="title">
              <!-- <i class="el-icon-s-fold"></i> -->
              <div class="header_more">
                <img src="../assets/images/more.png" alt="">
              </div>
            </template>
            <div v-for="(tiem, i) in item" :key="i">
              <el-menu-item v-if="tiem.rowe.length == 0" :index="'/' + tiem.path">{{ tiem.menuName }}</el-menu-item>
              <el-menu v-if="tiem.rowe.length > 0" class="yidonTitle" router background-color="rgba(19, 23, 130, 0.898) "
                mode=" horizontal" menu-trigger="click">
                <el-submenu index="2">
                  <template slot="title">
                    {{ tiem.menuName }}
                  </template>
                  <el-menu-item v-for="(tm, si) in tiem.rowe" :key="si" :index="'/' + tm.path">{{
                    tm.menuName
                  }}</el-menu-item>
                </el-submenu>
              </el-menu>
            </div>

            <!-- <el-menu-item index="/case">案例</el-menu-item> -->
            <!-- <el-menu-item index="/new">新闻</el-menu-item> -->
            <!-- <el-menu class="yidonTitle" router background-color="rgba(19, 23, 130, 0.898) " mode=" horizontal"
              menu-trigger="click">
              <el-submenu index="2">
                <template slot="title">
                  关于
                </template>
                <el-menu-item index="/about">简介</el-menu-item>
                <el-menu-item index="/Introduce">荣誉</el-menu-item>
              </el-submenu>
            </el-menu> -->
          </el-submenu>
        </el-menu>
      </el-row>
    </el-col>
    <el-col :span="18" class="navbar1">
      <el-row class="right" v-for="(item, index) in datas" :key="index">
        <el-col v-for="it in item" :key="it.menuId" :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <router-link v-if="it.rowe.length == 0" :to="'/' + it.path">{{ it.menuName }}</router-link>
          <el-menu v-if="it.rowe.length > 0" router background-color="transparent" text-color="#FFF"
            active-text-color="#fff" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-submenu index="1">
              <div slot="title" @click="funAbout(it.path)">
                {{ it.menuName }}
              </div>
              <el-menu-item :index="'/' + i.path" v-for="(i, ins) in it.rowe" :key="ins"> {{ i.menuName }}
              </el-menu-item>
              <!-- <el-menu-item index="/Introduce">荣誉</el-menu-item> -->
            </el-submenu>
          </el-menu>
        </el-col>

        <!-- <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <router-link to="/home">首页</router-link>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <router-link to="/case">案例</router-link>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <router-link to="/new">新闻</router-link>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-menu router background-color="transparent" text-color="#FFF" active-text-color="#fff" class="el-menu-demo"
            mode="horizontal" @select="handleSelect">
            <el-submenu index="1">
              <div slot="title" @click="funAbout">
                关于
              </div>
              <el-menu-item index="/about">简介</el-menu-item>
              <el-menu-item index="/Introduce">荣誉</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-col> -->
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
import { fileUrls, getMenu } from '@/api/index.js'

export default {
  name: 'HeaderIndex',
  data() {
    return {
      fileUrls,
      topNavBg: {
        activeIndex: '1',
        backgroundColor: ''
      },
      keypath: '',
      datas: [],
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
    this.getwebMenu()
  },
  methods: {
    async getwebMenu() {
      const res = await getMenu('M')

      if (res.code === 200) {
        // console.log(res.rows);
        this.datas = []
        const arr = JSON.parse(JSON.stringify(res.data))
        this.formenu(arr)
        this.datas.sort((a, b) => a.orderNum - b.orderNum)
        // console.log(this.datas.sort((a, b) => a.orderNum - b.orderNum));
        // console.log(this.datas);
        this.forDataList(this.datas)
        // console.log(this.datas);

        // this.datas.forEach(ent => {
        //   console.log(ent.menuName);
        //   console.log(ent.path);
        // })
      }
    },

    formenu(arr) {
      // let
      arr.forEach(ent => {
        let srots = ''
        if (ent.lv === '一级菜单') {
          srots = 1
        } else if (ent.lv === '二级菜单') {
          srots = 2
        } else if (ent.lv === '三级菜单') {
          srots = 3
        } else if (ent.lv === '四级菜单') {
          srots = 4
        }
        const dataArr = {
          z_srot: srots,
          menuName: ent.menuName,
          menuType: ent.menuType,
          menuId: ent.menuId,
          path: ent.path,
          orderNum: ent.orderNum,
          lv: ent.lv,
          roles: JSON.parse(JSON.stringify(ent.roles))
        }
        this.datas.push(dataArr)
        // }
      })
    },
    forDataList(parse) {
      // console.log(parse);
      const result = [];
      let currentLevel = 0;
      // let currentObj = null;
      const res = [];

      for (let i = 0; i < parse.length; i++) {
        currentLevel++
        // console.log(parse[i]);
        for (let j = 0; j < parse.length; j++) {
          // console.log(parse[j], j);
          if (parse[j].z_srot === currentLevel && currentLevel === 1) {
            parse[j].rowe = []
            res.push(parse[j])
          } else if (parse[j].z_srot === currentLevel && currentLevel === 2) {
            parse[j].rowe = []
            res[3].rowe.push(parse[j])
          }
        }
      }
      result.push(res)
      this.datas = result
      console.log(this.datas);
    },
    funAbout() { // 点击关于默认进入简介页
      if (this.keypath != '/about') {
        this.$router.push('/about')
      }
    },
    handleSelect(key, keyPath) {
      this.keypath = key
      // console.log(key, keyPath);
    },
    handleScroll() {
      const scrollTop = window.pageYOffset || (window.documentElement && window.documentElement.scrollTop) || document.body.scrollTop
      // const scrollTop = window.pageYOffset || window.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop) {
        this.topNavBg.backgroundColor = `rgba(19,23,130,${scrollTop / (scrollTop + 60)})`
        this.backgroundColor = `rgba(126, 206, 244,${scrollTop / (scrollTop + 60)})`
      } else if (scrollTop === 0) {
        // this.topNavBg.backgroundColor = 'none' //  还是有颜色，为完全透明
        this.topNavBg.backgroundColor = 'rgba(19,23,130,0)' // 设置回到顶部时，背景颜色为透明
        this.backgroundColor = 'rgba(126, 206, 244, 1)'
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }

}
</script>
<style lang="less" scoped>
/deep/ .el-icon-arrow-down:before {
  //修改导航栏关于右侧图标
  content: "";
  font-size: 18px;
  color: #fff;
}

/deep/ .el-icon-arrow-right:before {
  //修改移动端导航栏关于右侧图标
  content: "";
  font-size: 18px;
  color: #fff;
}

/deep/ .el-submenu {
  width: 100%;
}

/deep/ .el-menu--popup {
  min-width: 100px !important;
}

/deep/ .el-menu-item {
  min-width: 100px !important;

}

/deep/ .el-submenu__title {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

/deep/ .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
  // background-color: rgba(19, 23, 130, 0.898) !important;
  background-color: rgba(126, 206, 244, 1) !important;
  width: 100%;
}

// }
// /deep/ .is-disabled {
//   background-color: rgba(19, 23, 130, 0.898) !important;


// }

@media header and (max-width: 940px) {
  .el-menu-item {
    background-color: #ecf5ff !important;

  }

  .el-menu--horizontal {
    display: none;
  }

}

/deep/ .el-submenu__title {
  // background-color: rgb(204, 204, 204, .7);
  // background-color: rgba(19, 23, 130, 0.1) !important;
  color: #fff !important;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 50px;
  padding: 30px 0;
  justify-content: space-around;
  color: rgb(54, 142, 142);

  .navbar {
    display: none;

    .el-menu {
      .el-menu--popup {
        width: 180px;
      }
    }
  }

  @media screen and (max-width: 940px) {

    .navbar {
      display: block;

      .header_more {
        width: 25px;
        height: 20px;

        img {
          width: 100%;
        }
      }

      /deep/ .el-submenu__title {
        display: -webkit-inline-box;
        background-color: rgba(19, 23, 130, 0) !important;
        color: #fff !important;
        // width: 100%;
      }

      .yidonTitle {



        /deep/.el-submenu__icon-arrow {
          margin-left: 25px !important;
        }
      }
    }

  }

  @media screen and (max-width:940px) {
    .navbar1 {
      display: none;
      // visibility: hidden;
      // opacity: 0;
    }
  }

  .left {
    position: relative;

    // padding-left: 30px;
    img {
      margin: 4px 40px 0px 10px;
      height: 24px;
    }

    span {
      font-size: 22px;
      font-weight: normal;
      color: #F6F6F6;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-left: 20px;
    }
  }

  .right {
    display: flex;
    padding-right: 0px;
    // flex-direction: row-reverse;
    justify-content: flex-end;

    .el-col {
      cursor: pointer;
      width: auto;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      height: 40px;
      padding: 0 4%;

      .router-link-active {
        padding: 2px 5px;
        border: 2px solid #fff;
        border-radius: 5px
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
  }



  .el-menu {
    border-bottom: none;
    border-bottom-color: none;

    .el-icon-s-fold {
      color: #fff;
      font-size: 30px;
    }
  }
}

.el-menu-item {
  // background-color: #ecf5ff !important;
  background-color: rgba(126, 206, 244, 0.898) !important;
  // background-color: rgba(19, 23, 130, 0.898) !important;

  color: #fff !important;
}

.el-menu {
  // background-color: rgba(19, 23, 130, 0.898) !important;

  .yidonTitle {


    /deep/ .el-submenu.is-opened {

      >.el-submenu__title {
        // display: flex;

        .el-submenu__icon-arrow {
          // transform: rotateZ(90deg) !important;

        }
      }
    }
  }
}
</style>
