import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/reset.css'
import 'element-ui/lib/theme-chalk/display.css'
import '@/api/request.js'
// import { createApp } from 'vue'
// import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
// Vue.component('QuillEditor', QuillEditor)
// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// // 初始化高德地图的 key 和插件
// VueAMap.initAMapApiLoader({
//   key: '20f501c158388be6220be7151fd7fbd3',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   v: '1.4.4'
// })

// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// 初始化高德地图的 key 和插件
// VueAMap.initAMapApiLoader({
//   key: '20f501c158388be6220be7151fd7fbd3',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   v: '1.4.4'
// })

Vue.use(ElementUI)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
