import mockAjax from './ajaxMock'
import axios from 'axios'
axios.defaults.baseURL = '/scyhxykj'
const fileUrls = ''

const reqBanners = () => mockAjax({ url: '/banner.json', method: 'get' })
const getwebMenu = (data) => {
  return axios.get(`/system/admin/point/${data}`)
}
// 简介
const getAbout = (data) => {
  return axios.get(`/system/admin/aboutCompany?pageSize=${data.pageSize}&pageNum=${data.pageNum}`)
}
// 荣誉
const getIntroduce = (data) => {
  return axios.get(`/system/admin/aboutHonor?pageSize=${data.pageSize}&pageNum=${data.pageNum}`)
}
// 关于顶部轮播
const getLIsta = () => {
  return axios.get('/system/admin/lista')
}
// 首页轮播
const getlisth = () => {
  return axios.get('/system/admin/listh')
}

// Logo信息
const getfind = () => {
  return axios.get('/system/admin/findByEnable')
}
// 案例列表
const getexampleManager = (data) => {
  return axios.get(`/system/admin/exampleManager?pageSize=${data.pageSize}&pageNum=${data.pageNum}`)
}
// 案例详情
const getexampleCenter = (id) => {
  return axios.get(`/system/admin/exampleCenter/${id}`)
  // admin / exampleCenter / 3
}
// 轮播图公共方法
const getliste = (data) => {
  return axios.get(`/system//admin/${data}`)
}
// 新闻列表
const getnewsManager = (data) => {
  return axios.get(`/system/admin/newsManager?pageSize=${data.pageSize}&pageNum=${data.pageNum}`)
}
//首页新闻轮播图
const getnewsimg = (data) => {
  return axios.get('/system/admin/newsManager')
}
// 新闻详情页
const getnewsId = (data) => {
  return axios.get(`/system/admin/news/${data.id}`)
}
// 菜单信息
// /admin/webMenu/{type}
const getMenu = (data) => {
  // console.log(data);
  return axios.get(`/system/admin/webMenu/${data}`)
}
export {
  fileUrls,
  getLIsta, reqBanners, getAbout, getIntroduce, getfind, getwebMenu,
  getlisth, getexampleManager, getexampleCenter, getliste, getnewsManager,
  getMenu, getnewsimg, getnewsId
}
