import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dibugao: 0,
    query: { //  案例页分页器
      pageSize: 4,
      pageNum: 1
    },
    queryNew: { //  新闻页分页器
      pageSize: 2,
      pageNum: 1
    },
    queryintr: { //  荣誉页分页器
      pageSize: 4,
      pageNum: 1
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
