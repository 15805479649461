import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [

  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home/index.vue')
  },
  {
    path: '/new',
    name: 'new',
    component: () => import('../views/New/index.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About/index.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/Case/index.vue')
    // children: [
    //   {
    //     path: 'casedetails/:id',
    //     name: 'casedetails',
    //     props: true,
    //     component: () => import('../views/Case/CaseDetails.vue')
    //   }
    // ]
  },
  {
    path: '/Introduce',
    name: 'Introduce',
    component: () => import('../views/Introduce/index.vue')
  },
  {
    path: '/newsDetail/:id',
    name: 'newsDetail',
    props: true,
    component: () => import('../views/New/NewsDetail.vue')
  },
  {
    path: '/casedetails/:id',
    name: 'casedetails',
    props: true,
    component: () => import('../views/Case/CaseDetails.vue')
  },

  {
    path: '/IntroduceDetails/:id',
    name: 'IntroduceDetails',
    props: true,
    component: () => import('../views/Introduce/IntroduceDetails.vue')
  },
  {
    path: '',
    redirect: '/home'
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
