import axios from 'axios'
// axios.defaults.baseURL = '/semi/'
// console.log(axios.defaults.baseURL, 89889);
export const fileUrl = '/semi/file/'

axios.interceptors.response.use(resp => {
  return resp.data
}, error => {
  return Promise.reject(error)
})

export const search = (table, data) => {
  return axios.post(`/semi/enhance/${table}`, data)
}
export const getOne = (table, id) => {
  return axios.get(`/semi/common/${table}/${id}`)
}
export const getCase = (id) => {
  return axios.get(`/semi/templateData?caseCenterId=${id}`)
}
